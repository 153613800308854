function Reklamfilm() {
    return (
        <div className="flex items-center justify-center h-[950px]">
            <div className="absolute">
                <video
                    className="w-full z-10"
                    autoPlay
                    loop
                    muted
                >
                    <source src="images\videos/GamerunAD_DayOne_1 (1).mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>


    );
}

export default Reklamfilm;