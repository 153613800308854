import Carousel from "../components/Carusel";
import Timeline from "../components/Home/Timeline";
import ScrollAppear from '../components/ScrollAppear'

import { useState, useEffect } from "react";
function About() {

    let slides = [
        { id: 1, Name: "Viktor Kilestad", Title: "Developer", Bild: "images/imagesOfTeam/Viktor.jpg" },
        { id: 2, Name: "Axel Eriksson", Title: "CEO", Bild: "images/imagesOfTeam/Axel.jpg" },
        { id: 3, Name: "Filip Hermansson", Title: "Economist", Bild: "images/imagesOfTeam/Filip.jpg" }
    ]
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [slides.length]);
    return (
        <div>

            <div className="text-white md:w-[80vw] sm:w-[90vw] w-[90vw] mx-auto pt-40">
                <main className="">
                    <article className="mt-20 ">

                        <ScrollAppear>
                            <div className=" flex flex-col justify-center ">
                                <h2 className="md:text-[60px] text-[32px] font-bold">General information about the app</h2>
                                <p className="text-[22px] my-4 md:mb-20 mb-10">
                                    We came up with GameRun, as it is called, when we sat together one day and thought about today's youth. Then the thought struck us that the young people move far too little. Then we started discussing how to get young people and adults to be more active. Then Axel realized that an app is good for our evolution and can change lives.
                                </p>                                
                            </div>
                        </ScrollAppear>
                        <div className="  flex justify-center items-center">
                            <div className="md:w-[100%] w-full relative md:ml-20">
                                {slides.map((slide, index) => (
                                    <Carousel key={index} slide={slide} isActive={index === currentSlide} index={index} currentSlide={currentSlide} />
                                ))}
                            </div>    
                        </div>
                        
                        <ScrollAppear>
                            <ul className="flex md:flex-row sm:flex-col flex-col justify-center md:mt-[700px] mt-[500px]">
                                <li>
                                    <img src="images\imagesOfTeam/finn upp.png" alt="mario thumb 1" className=" md:m-8 md:rounded-l-xl rounded-t-xl" />
                                </li>
                                <li>
                                    <img src="images\imagesOfTeam/MicrosoftTeams-image (1).png" alt="mario thumb 2" className=" md:m-8 md:rounded-r-xl rounded-b-xl" />
                                </li>
                            </ul>
                        </ScrollAppear>
                        <ScrollAppear>
                            <p className="text-[22px] my-20 mb-4">
                                We are in the situation of having to produce a product, and therefore our next step is to get in touch with a programmer. If you want to help with the process contact either info.gamerun@gmail.com or click contact down in the bottom section "contact".
                            </p>
                        </ScrollAppear>
                    </article>

                </main>

            </div>
            <ScrollAppear>
                <Timeline />
            </ScrollAppear>
        </div>
    );
}

export default About;