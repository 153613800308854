const Carousel = ({ slide, isActive, index, currentSlide }) => {
    const isCurrent = index === currentSlide;
    const opacity = isCurrent ? 100 : 40;
    console.log(`${slide.Name + ' ' + slide.id } Current:  ` + (currentSlide))
    return (
      <div
        className={`absolute text-white test text-center pb-10 rounded-3xl transition-all duration-500 ease-in-out transform  w-[200px] md:w-[300px] ${
          isCurrent ? 'opacity-100 translate-x-0 z-20' : 'opacity-40'
        }`}
        style={{
            left: isCurrent ? '30%' : slide.id === 1 && currentSlide === 0 ?'0%': slide.id === 2 ?'60%': slide.id === 3 && currentSlide === 1  ? '60%': '0%'
            // index < currentSlide ? '80%' : 
            ,
            opacity: index !== currentSlide ? '20%' : 
            // index !== currentSlide-1 ?'0%':
            index === currentSlide ?'100%':'',
        }}
      >
        <img src={slide.Bild} alt={slide.Name} className="rounded-t-3xl" />
        <h1 className="md:text-[2rem] sm:text-[1rem] text-[1rem] font-bold mt-8">{slide.Name}</h1>
        <h2 className="md:text-[1rem] sm:text-[1rem] text-[1rem] font-semibold">{slide.Title}</h2>
      </div>
    );
  };
export default Carousel;