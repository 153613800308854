import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'

function ContactForm() {
    // State variables to manage form input values and error messages
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Mail, setMail] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [errore, setError] = useState('');

    // const [loading, setLoading] = useState(false);

    const sendTest = (event) => {
        event.preventDefault();

        // Prepare content to send via email
        let sendContent = {
            Name: FirstName + ' ' + LastName,
            Mobile: Mobile,
            Mail: Mail,
            Subject: Subject,
            Message: Message
        }

        // Validate form inputs
        if (
            Mail.includes('@' && '.') && // Check for a basic email format
            FirstName !== '' &&
            LastName !== '' &&
            Mobile !== '' &&
            Message !== ''
        ) {
            // Send email using emailjs library
            emailjs
                .send(
                    process.env.REACT_APP_SERVICE_ID,
                    process.env.REACT_APP_CONTACT_TEMPLATE_ID,
                    sendContent,
                    process.env.REACT_APP_PUBLIC_KEY
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                        setError('Error');
                    }
                );

            // Clear all input values in the form and set a success message
            setFirstName('');
            setLastName('');
            setMobile('');
            setMail('');
            setMessage('');
            setSubject('');
            setError('Medellande skickad'); // Message indicating successful submission
        } else {
            setError('Uppgifter är ej giltiga'); // Error message for invalid input
        }
    };
    const ChangeSubject = (newSubject) => {
        setSubject((prevSubject) =>
          prevSubject === newSubject ? '' : newSubject
        );
        
    };

    return (
        <form className="md:w-[90%] w-full flex md:flex-row sm:flex-col flex-col rounded-3xl py-10 bg-white  p-4 " onSubmit={sendTest}>
            <div className='md:w-1/3 h-full test md:text-left text-center text-white p-8 rounded-lg'>
                <h1 className='text-[2rem] font-bold'>Contact Information</h1>
                <p className='md:text-[1rem] text-[1rem] font-medium mt-4'>Say something to start a live chat!</p>
                <div className='mt-20 md:pb-60'>

                    <p className='md:text-[1rem] text-[1rem] font-medium mt-4'>
                        <FontAwesomeIcon icon={faPhoneVolume} style={{ color: "#ffffff", }} className='mr-4' />
                        079 341 32 09</p>
                    <p className='md:text-[1rem] text-[1rem] font-medium mt-10'>
                        <FontAwesomeIcon icon={faEnvelope} style={{ color: "#ffffff", }} className='mr-4' />
                        Info.gamerun@gmail.com</p>
                    <p className='md:text-[1rem] text-[1rem] font-medium mt-10'>
                        <FontAwesomeIcon icon={faLocationDot} style={{ color: "#ffffff", }} className='mr-4' />
                        Finnslätten, 721 36. Västerås, Sverige</p>
                </div>
            </div>

            <div className='md:w-2/3 md:ml-10'>
                <div className=" flex md:flex-row flex-col mt-10 mx-4">

                    <div className=" md:mx-4 md:w-1/2 w-full">
                        <label htmlFor="First Name" className=" " >First Name</label>
                        <input
                            type="text"
                            id="First Name"
                            name="First Name"
                            className="p-2  w-full border-b-2 border-black bg-transparent rounded-none"
                            value={FirstName}
                            onChange={event => setFirstName(event.target.value)}
                        />
                    </div>
                    <div className=" md:mx-4 md:w-1/2 w-full">
                        <label htmlFor="Last Name" className="" >Last Name</label>
                        <input
                            type="text"
                            id="Last Name"
                            name="Last Name"
                            className="p-2  w-full border-b-2 border-black bg-transparent rounded-none"
                            value={LastName}
                            onChange={event => setLastName(event.target.value)}
                        />
                    </div>
                </div>
                <div className=" flex md:flex-row flex-col mt-10 mx-4">
                    <div className=" md:mx-4 md:w-1/2 w-full">
                        <label htmlFor="First Name" className="   " >Email</label>
                        <input
                            type="text"
                            id="Email"
                            name="Email"
                            className="p-2  w-full border-b-2 border-black bg-transparent rounded-none"
                            value={Mail}
                            onChange={event => setMail(event.target.value)}
                        />
                    </div>
                    <div className=" md:mx-4 md:w-1/2 w-full">
                        <label htmlFor="Phone Number" className="" >Phone Number</label>
                        <input
                            type="text"
                            id="Phone Number"
                            name="Phone Number"
                            className="p-2  w-full border-b-2 border-black bg-transparent rounded-none"
                            value={Mobile}
                            onChange={event => setMobile(event.target.value)}
                        />
                    </div>
                </div>

                <p className='md:text-[1rem] text-[1rem] font-medium mt-10 md:mx-8 mx-4'>Select Subject?</p>
                <div className='flex md:flex-col flex-col md:mx-8 mx-4 my-4 md:text-[1rem] text-[1rem] font-medium'>
                    <div className='flex md:flex-row flex-col'>
                        <div className='flex w-20'>
                            <input 
                            type="checkbox" 
                            id='Frågor' 
                            value="Frågor" 
                            checked={Subject === 'Frågor'}
                            onChange={() => ChangeSubject("Frågor")} />
                            <label htmlFor='Frågor' className='mx-4'>Frågor</label>
                        </div>
                        <div className='flex md:ml-28'>
                            <input 
                            type="checkbox" 
                            id='Samarbeten' 
                            value="Samarbeten" 
                            checked={Subject === 'Samarbeten'}
                            onChange={() => ChangeSubject("Samarbeten")}/>
                            <label htmlFor='Samarbeten' className='mx-4'>Samarbeten</label>
                        </div>                        
                    </div>
                    <div className='flex md:flex-row flex-col'>
                        <div className='flex '>
                            <input
                            type="checkbox"
                            id="Nyhetsbrev"
                            value="Nyhetsbrev"
                            checked={Subject === 'Nyhetsbrev'}
                            onChange={() => ChangeSubject("Nyhetsbrev")}
                            />
                            <label htmlFor='Nyhetsbrev' className='mx-4'>Nyhetsbrev</label>
                        </div>
                        <div className='flex md:ml-16'>
                            <input 
                            type="checkbox" 
                            id='Sponsor'
                            value="Sponsor" 
                            checked={Subject === 'Sponsor'}
                            onChange={() => ChangeSubject("Sponsor")}
                            />
                            <label htmlFor='Sponsor' className='mx-4'>Sponsor</label>
                        </div>                        
                    </div>

                </div>
                <div className=' md:m-8 m-4'>
                    <label htmlFor="message" className="md:text-[1rem] text-[1rem] font-medium  top-[-13px] left-[12px]">Meddelande</label>

                    <textarea
                        name="message"
                        id='message'
                        placeholder='Write your message...'
                        value={Message}
                        onChange={event => setMessage(event.target.value)}
                        className="w-full md:h-[200px] h-[100px]  border-2 rounded-xl border-black bg-transparent p-4"
                    >

                    </textarea>
                </div>
                <div className='flex md:justify-end justify-center mx-8'>
                    <button className='bg-[#10102C] text-white px-10 md:py-6 py-2 rounded-xl '>
                        <p>Send Message</p>
                    </button>                    
                </div>

            </div>
        </form>

    )

}

export default ContactForm;
