import { faTools, faShoppingCart, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Services() {
    return (
        <div>
                        <div className="col-lg-12 text-center text-white py-[70px]">
                <h2 className="text-[40px] font-bold mb-[15px]">
                    Services
                </h2>
                <h3 className="text-[13px] text-[#6c757d]">
                    Here you can read about our services, which we offer our customers.
                </h3>
            </div>

            <div className='text-white flex md:flex-row flex-col justify-center mx-auto w-[70vw] gap-8'>
                <div className='md:w-1/3 flex flex-col justify-center'>
                    <div className='bg-[#26335D] h-[150px] w-[150px] text-[4rem] rounded-full flex justify-center items-center mx-auto' >
                        <FontAwesomeIcon icon={faShoppingCart} />
                    </div>
                    <div className='flex flex-col text-center'>
                        <h4 className="font-bold text-[1.5rem]">
                            Products
                        </h4>
                        <p className="text-[#6c757d]">
                            Our product is chosen by us. We want to offer our customers the best on the market, at the lowest possible price. We're always looking for new fun features to provide.
                        </p>                        
                    </div>

                </div>
                <div className='md:w-1/3 flex flex-col justify-center'>
                    <div className='bg-[#26335D] h-[150px] w-[150px] text-[4rem] rounded-full flex justify-center items-center mx-auto' >
                        <FontAwesomeIcon icon={faUserShield} />
                    </div>
                    <div className='flex flex-col text-center'>
                        <h4 className="font-bold text-[1.5rem]">
                            Our Customers
                        </h4>
                        <p className="text-[#6c757d]">
                            For us, it's very important that our customers are satisfied with their purchase, service, or installation. Therefore, we will do everything to keep all our customers as satisfied customers.
                        </p>                        
                    </div>

                </div>
                <div className='md:w-1/3 flex flex-col justify-center'>
                    <div className='bg-[#26335D] h-[150px] w-[150px] text-[4rem] rounded-full flex justify-center items-center mx-auto' >
                        <FontAwesomeIcon icon={faTools} />
                    </div>
                    <div className='flex flex-col text-center'>
                        <h4 className="font-bold text-[1.5rem]">
                            Help
                        </h4>
                        <p className="text-[#6c757d]">
                            If you can't install the app yourself due to time, energy, or knowledge constraints, we have an installation service available on YouTube after the app launch. We'll guide you step by step through setting up your account.
                        </p>                        
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Services;