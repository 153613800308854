

import Services from '../components/Home/Services'
import ScrollAppear from '../components/ScrollAppear'

export default function Home(){
    return(
        <div className="flex flex-col justify-center">
            <div className="w-[70vw] flex md:flex-row sm:flex-col flex-col justify-center items-center mx-auto text-white md:py-52 pb-10 pt-20">
                <div className="flex flex-col md:w-2/5">
                    <div className="flex-col md:text-left text-center">
                        <h1 className="text-[3.5rem] font-bold">Run, Play, Conquer</h1>
                        <p className="md:text-[2.1rem] text-[1.5rem] font-medium mt-4">The product that makes parents satisfied</p>
                    </div>
        
                    <div className="flex flex-row justify-center items-center">
                        <a href="https://www.apple.com/se/search/GameRun?src=globalnav">
                            <img className="w-[32vw] border-2 border-gray-500 rounded-lg h-auto" src="images/icons/AppStore.png" alt=""/>
                        </a>
                        <a href="https://play.google.com/store/search?q=GameRun&c=apps">
                            <img className="w-[40vw] h-auto" src="images/icons/GooglePlay.png" alt=""/>
                        </a>    
                    </div>
                </div>
                <div className="flex items-center justify-center ">
                    <img className="md:w-[900px] md:h-[500px] animate-pulse" src="images/phone/Mockup start image.png" alt=""/>
                </div>
            </div>
            <ScrollAppear>
                <div className="relative bg-gradient-to-r from-[#26335D] to-[#26335D] border-white border-2 md:mb-20">
                    <div className="md:w-[50vw] w-[60vw] flex justify-evenly items-center mx-auto md:gap-20 gap-6">
                        <img className="w-1/5" src="images/sponsors/image (1).png" alt=""/>
                        <img className="w-1/5" src="images/sponsors/K-bygg.png" alt=""/>
                        <img className="w-1/5" src="images/sponsors/webtrap_logo2.png" alt=""/>
                        <img className="w-1/5" src="https://www.ahlsellworkwear.se/globalassets/site-images/logo_workwear.svg" alt=""/>
                        <img className="w-1/5" src="images/sponsors/Zebra_lotta.png" alt=""/>
                    </div>
                </div>
            </ScrollAppear>
            <ScrollAppear>
                <Services/>
            </ScrollAppear>
            <ScrollAppear>
                <section className="text-white flex md:flex-row flex-col test rounded-l-[48px] rounded-r-[24px] justify-center mx-auto md:w-[70vw] sm:w-[90vw] w-[90vw] relative border-[4px] border-t-0 rounded-3xl  md:mt-40 mt-20">
                    <div className="md:w-2/5 h-[320px]">
                        <img src="images/stocks/desktop-wallpaper-running-motivation-keep-running.jpg" alt="" className='md:w-[200px] w-full h-[300px] md:h-[320px] left-10 -top-[24px] rounded-l-3xl object-cover'/>
                    </div>
                    <div className=" p-[48px]">
                        <h3 className='text-[25px] font-bold'>Why should you use GameRun?</h3>
                        <p className='text-[18px] font-medium mt-[25px]'>
                            While this app can be motivating and enjoyable, it's essential to remember that physical activity itself is a valuable and rewarding experience. Using the app as a tool to supplement a healthy lifestyle rather than relying solely on rewards is the most balanced approach to long-term health and well-being.
                        </p>
                    </div>

                </section>
            </ScrollAppear>
            <ScrollAppear>
                <div className="md:w-[70vw] w-[90vw] flex md:flex-row flex-col mx-auto text-white md:my-40 my-20 px-10 pt-10 border rounded-[20px]">
                    <div className="md:w-1/3 w-full flex flex-col justify-center mx-auto">
                        <h1 className="md:text-[2.5rem] sm:text-[2.6rem] text-[1.8rem] font-bold">
                            Download the Gamerun App to get started
                        </h1>
                        <div className="flex md:flex-row flex-col ">
                            <button className="md:w-[300px] w-[190px]">
                                <img src="/images/icons/AppStore.png" alt='' className=' border-gray-500 h-auto' />
                            </button>
                            <button className="md:w-[330px] w-[200px]" >
                                <img src="/images/icons/GooglePlay.png" alt='' className='w-[] h-auto'/>
                            </button>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-4/6 ml-auto mt-8"> {/* Add ml-auto class here */}
                        <img className=" mx-auto h-auto" src="images/phone/PhonePreview.png" alt=""/>
                    </div>
                </div>
            </ScrollAppear>
        </div>   
    )
    
}
