import ScrollAppear from '../components/ScrollAppear'

function Produkt() {
    return (
        <div>
            <div className="md:w-[70vw] w-[90vw] mx-auto text-white">
                <div className="flex md:flex-row flex-col md:justify-between justify-center py-20 mt-20">
                    <div className="my-auto md:mx-0 mx-auto 5md:w-2/">
                        <div>
                            <h1 className="md:text-[4rem] text-[3rem] font-bold">Our product</h1>
                            <p className="md:text-[2.1rem] text-[1.5rem] font-medium mt-[10px]">Look into our latest product</p>                            
                        </div>
                        <div className="flex flex-row">
                            <button className="p-4 m-4 bg-blue-500 rounded-3xl">
                                <a href="sign__up.html">Download</a>
                            </button>
                            <button className="p-4 m-4 border-2 rounded-3xl">
                                <a href="reklamfilm.html">Learn More</a>
                            </button>                            
                        </div>

                    </div>
                    <div className="md:mx-0 mx-auto">
                        <img src="images\phone/iPhone 13 Pro111.png" alt="" className=" md:h-[600px] h-[400px]  my-10"/>
                    </div>
                </div>
                <ScrollAppear>
                    <main className="">
                        <article>
                            <h2 className="md:text-[60px] text-[32px] font-bold">General information about the app</h2>
                            <p className="text-[22px] my-4"> 
                                We at GameRun want there to be an app that is fun and good for your health. Our ultimate goal for this app is to make children and young people think it's fun to move. Studies have shown that the new generation of children move far too little and parents have a hard time getting them to move. This is exactly where GameRun helps adults motivate their children.
                            </p>
                            {/* <p className="text-[22px] mt-40 mb-4"> 
                                Right now GameRun seems to have a very bright future, there are many new and creative ideas. All the founders seem very positive about making the GameRun idea a reality. There are also ideas about giving GameRun some kind of game world, but nothing is decided. Therefore, Game Run is a good opportunity that will change your life, in the way that movement makes you feel better and you will have more energy in everyday life.
                            </p>
                            <p className="text-[22px] my-12">
                                GameRun will make your everyday life and your "life" more fun in one way or another. If you move, your body will feel better mentally and you will be, among other things, motivated to invent things, which you might not have been if you had not been able to.
                            </p>
                            <p className="text-[22px] my-12">
                                GameRun will make your everyday life and your "life" more fun in one way or another. If you move, your body will feel better mentally and you will be, among other things, motivated to invent things, which you might not have been if you had not been able to.
                            </p> */}
                        </article>
                        <ul className="flex md:flex-row sm:flex-col flex-col justify-center">
                            <li>
                                <img src="images/phone/Layout Phone (Home).png" alt="mario thumb 1" className="h-[800px] md:m-8 my-8 md:my-0"/>
                            </li>
                            <li>
                                <img src="images/phone/Layout Phoen (Stats).png" alt="mario thumb 2" className="h-[800px] md:m-8 my-8 md:my-0"/>
                            </li>
                        </ul>
                    </main>
                </ScrollAppear>
            </div>
        </div>
    );
}

export default Produkt;