function Timeline() {
    return (
    <section className="text-white mt-20 mx-auto md:w-[80vw]">

        <div className="text-center">
            <h2 className="md:text-[5rem] text-[2rem] font-bold">TIMELINE</h2>
        </div>
        <div className="flex justify-center px-8">
            <ul className=" mx-auto">

                {/* First timeline item */}
                <li className=' flex items-center w-full md:pt-20 pt-10'>
                    <div className="md:h-[170px] md:w-[170px] h-[90px] w-[90px] border-[7px] border-white rounded-full">
                        <img className="rounded-full h-full object-cover" src="images/timeline/iPhone 13 Pro Max - 8.png" alt=""/>
                    </div>
                    <div className="w-2/3 md:h-[170px]  h-[80px]  pl-8 ">
                        <div className="text-[1.5rem] font-bold">
                            <h4>2021-01-25</h4>
                            <h4 className="">GameRun created</h4>
                        </div>
                        <p className="">We came with the solution of today's youth sedentary and named it "GameRun".</p>
                    </div>
                </li>
                <div className='flex md:ml-[85px] ml-[45px] md:h-[60px] h-40'>
                    <span className='border '></span>
                </div>

                {/* Second timeline item */}
                <li className=' flex  items-center'>
                    <div className="  md:h-[170px] md:w-[170px] h-[90px] w-[90px] border-[7px] border-white rounded-full ">
                        <img className="rounded-full h-full object-cover" src="images/timeline/finn upp.png" alt=""/>
                    </div>
                    <div className="w-2/3 md:h-[170px]  h-[80px] pl-8 ">
                        <div className="text-[1.5rem] font-bold">
                            <h4>2021-05-24</h4>
                            <h4 className="">Finn Upp Competition</h4>
                        </div>
                        <p className="">We contributed in a competition for innovators and received a second price in the category "Digial Solution".</p>
                    </div>
                </li>

                <div className='flex md:ml-[85px] ml-[45px] md:h-[60px] h-48'>
                    <span className='border '></span>
                </div>

                {/* Third timeline item */}
                <li className=' flex  items-center w-full'>
                    <div className=" md:h-[170px] md:w-[170px] h-[90px] w-[90px] border-[7px] border-white rounded-full ">
                        <img className="rounded-full h-full object-cover" src="images/timeline/Merchsweden2.png" alt=""/>
                    </div>
                    <div className="w-2/3 md:h-[170px]  h-[80px] pl-8 ">
                        <div className="text-[1.5rem] font-bold">
                            <h4>2023-09-7</h4>
                            <h4 className="subheading">UF Kickoff</h4>
                        </div>
                        <p className="">We were at the UF kickoff where we got more inspiration and ideas for our company.</p>
                    </div>
                </li>

                <div className='flex md:ml-[85px] ml-[45px] md:h-[60px] h-40'>
                    <span className='border '></span>
                </div>

                {/* forth timeline item */}
                <li className=' flex  items-center w-full pb-10'>
                    <div className=" md:h-[170px] md:w-[170px] h-[90px] w-[90px] test border-[7px] border-white rounded-full mb-4 flex justify-center items-center">
                        <h4 className='md:text-[18px] text-[8px] text-center font-bold'>Follow
                                <br/>
                                Our
                                <br/>
                                Journey!
                            </h4>
                    </div>
                </li>

            {/* Add similar modifications for the other list items */}

            </ul>
        </div>
        
    </section>
    );
}

export default Timeline;