import { useState } from 'react';
import { Link, useLocation  } from 'react-router-dom';

export default function Navbar(){
    const location = useLocation();

    const [showNav, setShowNav] = useState(false)

    const Show = () => {
        setShowNav(!showNav)
    }
    return (
        <nav className="fixed top-0 w-full bg-transparent text-white z-30">
            <div className="flex justify-between md:h-[80px] h-[20px] md:w-4/5 w-5/6 items-center mx-auto md:mt-10 sm:mt-6 mt-6">
                <Link to="/" className="flex items-center md:text-[2.5rem] text-[1.5rem] font-bold">
                    <img src="images\icons\vector.png" alt="img" className="mr-3 md:h-[40px] md:w-[40px] h-[30px] w-[30px]"/>
                    GameRun
                </Link>
                <div className="flex flex-col md:hidden" onClick={Show}>
                    <span className="border my-1 w-8"></span>
                    <span className="border my-1"></span>
                    <span className="border my-1"></span>
                </div>

                {showNav && (
                    <div className='absolute z-20 left-0 top-0 w-[100vw] h-[100vh] bg-slate-500 bg-opacity-60'>
                        <div className='pb-4 mt-[50px] test mx-10 rounded-[40px]'>
                            <div className="" onClick={Show}>
                                <p className="text-[2rem] ml-8 mt-4">X</p>
                            </div> 
                            <ul className="flex flex-col items-center ">
                                
                                <li className={`h-10 flex items-center p-4 mb-4 ${location.pathname === '/' ? 'border-b-2' : 'text-white'}`}>
                                    <Link to="/" onClick={Show}>Home</Link>
                                </li>
                                <li className={`h-10 flex items-center p-4 m-4 ${location.pathname === '/Produkt' ? 'border-b-2' : 'text-white'}`}>
                                    <Link to="/Produkt" onClick={Show}>Product</Link>
                                </li>
                                <li className={`h-10 flex items-center px-4 m-4 ${location.pathname === '/Trailer' ? 'border-b-2' : 'text-white'}`}>
                                    <Link to="/Trailer" onClick={Show}>Trailer</Link>
                                </li>
                                <li className={`h-10  flex items-center px-4 m-4 ${location.pathname === '/About' ? 'border-b-2' : 'text-white'}`}>
                                    <Link to="/About" onClick={Show}>About</Link>
                                </li>
                                <li className="bg-blue-500 p-2 rounded-md">
                                    <Link to="/Contact" className="button1" onClick={Show}>Contact Us</Link>
                                </li>
                            </ul>                            
                        </div>

                        
                    </div>
                )}

                <ul className="hidden md:flex justify-center items-center pl-8 pb-4">
                    <li className={`ml-[150px] h-20 flex items-center px-4 font-bold ${location.pathname === '/' ? 'border-b-2' : 'text-white'}`}>
                        <Link to="/">Home</Link>
                    </li>
                    <li className={`h-20 flex items-center px-4 font-bold ${location.pathname === '/Produkt' ? 'border-b-2' : 'text-white'}`}>
                        <Link to="/Produkt">Product</Link>
                    </li>
                    <li className={`h-20 flex items-center px-4 font-bold ${location.pathname === '/Trailer' ? 'border-b-2' : 'text-white'}`}>
                        <Link to="/Trailer">Trailer</Link>
                    </li>
                    <li className={`h-20  flex items-center px-4 font-bold ${location.pathname === '/About' ? 'border-b-2' : 'text-white'}`}>
                        <Link to="/About">About</Link>
                    </li>
                    <li className="bg-blue-500 p-2 rounded-md">
                        <Link to="/Contact" className="button1">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav>
    
    )
}
