import React, { useState, useEffect, useRef } from 'react';

const ScrollAppear = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const prevScrollY = useRef(0);

  const handleScroll = () => {
    const threshold = window.innerHeight * 0.8;
    const currentScroll = window.scrollY;

    if (componentRef.current) {
      const elementOffset = componentRef.current.offsetTop;

      if (currentScroll > elementOffset - threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      // Update the previous scroll position
      prevScrollY.current = currentScroll;
    }
  };

  useEffect(() => {
    // Check visibility on initial render
    handleScroll();

    // Set up scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className={`transition-all duration-[1500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      {children}
    </div>
  );
};

export default ScrollAppear;
