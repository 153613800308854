import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home.js';
import Produkt from './pages/Produkt.js';
import Reklamfilm from './pages/Reklamfilm.js';

import './App.css';
import Fotter from './components/Fotter.js';
import Navbar from './components/Navbar';
import About from './pages/About.js';
import Contact from './pages/Contact.js';

function App() {
    return (
        <div className="test">
            <Navbar/>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/produkt" element={<Produkt/>} />
                <Route path="/Trailer" element={<Reklamfilm/>} />
                <Route path="/About" element={<About/>} />
                <Route path="/Contact" element={<Contact/>} />
            </Routes>
           <Fotter/>
        </div>

    );
}

export default App;
